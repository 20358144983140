import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    NUC_DIALOG_CUSTOM_CONTENT_DATA,
    NucDialogCustomContentService
} from '@relayter/rubber-duck';
import {TeamModel} from '../../models/response/team.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {distinctUntilChanged, finalize, map} from 'rxjs/operators';
import {Toaster} from '../../classes/toaster.class';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {RoleModel} from '../../models/response/role.model';
import {UserModel} from '../../models/response/user.model';
import {Subscription} from 'rxjs';
import {TeamService} from '../../api/services/team.service';
import {UserService} from '../../api/services/user.service';
import {RoleService} from '../../api/services/role.service';
import {AppConstants} from '../../app.constants';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export interface ITransferOwnershipFormData {
    team: TeamModel;
}

class TransferOwnershipForm {
    newOwner: FormControl<DropdownItem<UserModel>>;
    newRoles: FormControl<RoleModel[]>;
    confirmText: FormControl<string>;
}

@Component({
    selector: 'om-transfer-ownership-form',
    templateUrl: './transfer-ownership-form.component.html',
    styleUrl: './transfer-ownership-form.component.scss'
})
export class TransferOwnershipFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);

    public team: TeamModel;
    public form: FormGroup<TransferOwnershipForm>;

    public roles: RoleModel[] = [];
    public users: DropdownItem<UserModel>[] = [];
    public totalUsers: number;
    public totalRoles: number;
    public pageSize = AppConstants.PAGE_SIZE_DEFAULT;

    private transferButton: ButtonConfig;

    public usersSubscription: Subscription;
    public rolesSubscription: Subscription;

    constructor(private userService: UserService,
                private roleService: RoleService,
                private teamService: TeamService,
                private dialogCustomContentService: NucDialogCustomContentService,
                @Inject(NUC_DIALOG_CUSTOM_CONTENT_DATA) modalData: ITransferOwnershipFormData) {
        this.team = modalData.team;
    }

    public ngOnInit(): void {
        this.getUsers();
        this.getRoles();
        this.initModalButtons();
        this.initForm();
    }

    public initForm(): void {
        this.form = new FormGroup({
            newOwner: new FormControl(null, Validators.required),
            newRoles: new FormControl(null, Validators.required),
            confirmText: new FormControl(null, Validators.pattern(/^Confirm$/))
        });

        this.form.statusChanges.pipe(
            map((status) => status === 'VALID'),
            distinctUntilChanged()
        ).subscribe((valid) => this.transferButton.disabled = !valid);
    }

    public getUsers(event?: IDropdownRequestDataEvent): void {
        if (this.usersSubscription && !this.usersSubscription?.closed) {
            this.usersSubscription.unsubscribe();
        }
        if (event?.reset) {
            this.users = [];
        }

        this.usersSubscription = this.userService.getUsers(this.team._id, [this.team.owner._id], event?.limit || this.pageSize, event?.offset,
            'fullName', 'asc', event?.search)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (res: ARPagedResponseDataModel<UserModel>) => {
                    this.users = this.users.concat(res.items.map(item => new DropdownItem<UserModel>(item.fullName, item, null, item.email)));
                    this.totalUsers = res.total;
                },
                error: (err: ARApiError) => Toaster.handleApiError(err)
            });
    }

    public getRoles(event?: IDropdownRequestDataEvent): void {
        if (this.rolesSubscription && !this.rolesSubscription?.closed) {
            this.rolesSubscription.unsubscribe();
        }
        if (event?.reset) {
            this.roles = [];
        }

        this.rolesSubscription = this.roleService.getRoles(this.team._id, event?.limit, event?.offset, 'name', 'asc', event?.search)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (res: ARPagedResponseDataModel<RoleModel>) => {
                    this.roles = this.roles.concat(res.items);
                    this.totalRoles = res.total;
                },
                error: (err: ARApiError) => Toaster.handleApiError(err)
            });
    }

    public transferOwnership(): void {
        this.transferButton.loading = true;
        const newOwner = this.form.value.newOwner.getValue();
        const body = {
            newOwnerId: newOwner._id,
            newRoleIds: this.form.value.newRoles.map(role => role._id)
        };

        this.teamService.transferOwnership(this.team._id, body)
            .pipe(
                finalize(() => this.transferButton.loading = false),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: () => {
                    Toaster.success(`Team ownership has been transferred to ${newOwner.getTitle()}`);
                    this.dialogCustomContentService.close(true);
                },
                error: Toaster.handleApiError
            });
    }

    private initModalButtons(): void {
        this.transferButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Confirm transfer', null, null, true);
        const transferAction = new FullModalActionModel(this.transferButton);
        const cancelAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel'));
        this.dialogCustomContentService.addDialogAction(cancelAction);
        this.dialogCustomContentService.addDialogAction(transferAction);

        cancelAction.observable.subscribe(() => this.dialogCustomContentService.close());
        transferAction.observable.subscribe(() => this.transferOwnership());
    }
}

import {Injectable} from '@angular/core';
import {BaseApiRequestService} from './base-api-request.service';
import {Observable} from 'rxjs';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {environment} from '../../../environments/environment';
import {ApiConstants} from '../api.constant';
import {TeamModel, TeamPatchModel} from '../../models/response/team.model';
import {JobModel} from '../../models/response/job.model';
import {QueryParams} from '../../classes/query-params';
import {Serialize} from 'cerialize';

export enum ETeamJobTypes {
    DELETE_TEAM_JOB = 'DELETE_TEAM_JOB',
}

export interface IDeleteTeamJobData {
    teamId: string;
}

export type ITeamJobData = IDeleteTeamJobData;

export interface ITransferOwnershipBody {
    newOwnerId: string;
    newRoleIds: string[];
}

@Injectable({
    providedIn: 'root'
})
export class TeamService extends BaseApiRequestService {
    public getTeam(teamId: string): Observable<TeamModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_PATH_ADMIN, ApiConstants.API_PATH_TEAMS, teamId]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, TeamModel);
        });
    }

    public getTeams(limit?: number, offset?: number): Observable<ARPagedResponseDataModel<TeamModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset);

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_PATH_ADMIN, ApiConstants.API_PATH_TEAMS], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, TeamModel);
        });
    }

    public patchTeam(teamId: string, teamPatchModel: TeamPatchModel): Observable<TeamModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_PATH_ADMIN, ApiConstants.API_PATH_TEAMS, teamId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = Serialize(teamPatchModel, TeamPatchModel);
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, TeamModel);
        });
    }

    public postJob(jobType: ETeamJobTypes, jobData: ITeamJobData): Observable<JobModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_PATH_ADMIN,
            ApiConstants.API_PATH_TEAMS,
            ApiConstants.API_PATH_JOBS]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, JobModel);
        });
    }

    public transferOwnership(teamId: string, body: ITransferOwnershipBody): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_PATH_ADMIN,
            ApiConstants.API_PATH_TEAMS,
            teamId, ApiConstants.API_METHOD_TRANSFER_OWNERSHIP]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PUT;
        options.url = url;
        options.body = body;

        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }
}

import { Injectable, ɵɵdefineInjectable } from '@angular/core';
import * as lib from 'papaparse/papaparse.min.js';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,uselessCode} checked by tsc
 */
class Papa {
  constructor() {
    this._papa = lib;
  }
  /**
   * Parse CSV to an array
   * @param {?} csv
   * @param {?=} config
   * @return {?}
   */
  parse(csv, config) {
    return this._papa.parse(csv, config);
  }
  /**
   * Convert an array into CSV
   * @param {?} data
   * @param {?=} config
   * @return {?}
   */
  unparse(data, config) {
    return this._papa.unparse(data, config);
  }
  /**
   * Set the size in bytes of each file chunk.
   * Used when streaming files obtained from the DOM that
   * exist on the local computer. Default 10 MB.
   * @param {?} value
   * @return {?}
   */
  setLocalChunkSize(value) {
    this._papa.LocalChunkSize = value;
  }
  /**
   * Set the size in bytes of each remote file chunk.
   * Used when streaming remote files. Default 5 MB.
   * @param {?} value
   * @return {?}
   */
  setRemoteChunkSize(value) {
    this._papa.RemoteChunkSize = value;
  }
  /**
   * Set the delimiter used when it is left unspecified and cannot be detected automatically. Default is comma.
   * @param {?} value
   * @return {?}
   */
  setDefaultDelimiter(value) {
    this._papa.DefaultDelimiter = value;
  }
  /**
   * An array of characters that are not allowed as delimiters.
   * @return {?}
   */
  get badDelimiters() {
    return this._papa.BAD_DELIMITERS;
  }
  /**
   * The true delimiter. Invisible. ASCII code 30.
   * Should be doing the job we strangely rely upon commas and tabs for.
   * @return {?}
   */
  get recordSeparator() {
    return this._papa.RECORD_SEP;
  }
  /**
   * Also sometimes used as a delimiting character. ASCII code 31.
   * @return {?}
   */
  get unitSeparator() {
    return this._papa.UNIT_SEP;
  }
  /**
   * Whether or not the browser supports HTML5 Web Workers.
   * If false, worker: true will have no effect.
   * @return {?}
   */
  get workersSupported() {
    return this._papa.WORKERS_SUPPORTED;
  }
}
/** @nocollapse */Papa.ngInjectableDef = ɵɵdefineInjectable({
  factory: function Papa_Factory() {
    return new Papa();
  },
  token: Papa,
  providedIn: "root"
});

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,uselessCode} checked by tsc
 */

export { Papa };


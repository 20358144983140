import {BaseApiRequestService} from './base-api-request.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiConstants} from '../api.constant';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARRequestOptions} from '@relayter/core';
import {JobModel} from '../../models/response/job.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class JobService extends BaseApiRequestService {

    public static readonly API_PATH_INTEGRATION = 'integration';
    public static readonly API_PATH_IMPORT = 'import';
    public static readonly API_PATH_PRODUCTS = 'products';
    public static readonly API_PATH_JOBS = 'jobs';
    public static readonly API_PATH_CAMPAIGNS = 'campaigns';
    public static readonly API_PATH_INDESIGN_LIBRARIES = 'indesign-libraries';

    public postImportJob(jobType: string, jobData: object, apiKey: string): Observable<JobModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            JobService.API_PATH_IMPORT,
            JobService.API_PATH_JOBS]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};
        options.headers = new HttpHeaders({[ApiConstants.RELAYTER_API_HEADERS.X_RELAYTER_API_KEY]: apiKey});

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, JobModel);
        });
    }

    public postProductImportJob(jobType: string, jobData: object, apiKey: string): Observable<JobModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            JobService.API_PATH_PRODUCTS,
            JobService.API_PATH_JOBS]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};
        options.headers = new HttpHeaders({[ApiConstants.RELAYTER_API_HEADERS.X_RELAYTER_API_KEY]: apiKey});

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, JobModel);
        });
    }

    public postCampaignImportJob(jobType: string, jobData: object, apiKey: string): Observable<JobModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            JobService.API_PATH_CAMPAIGNS,
            JobService.API_PATH_JOBS]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};
        options.headers = new HttpHeaders({[ApiConstants.RELAYTER_API_HEADERS.X_RELAYTER_API_KEY]: apiKey});

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, JobModel);
        });
    }

    public postInDesignibraryJob(jobType: string, jobData: object, apiKey: string): Observable<any> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            JobService.API_PATH_INDESIGN_LIBRARIES,
            JobService.API_PATH_JOBS]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};
        options.headers = new HttpHeaders({[ApiConstants.RELAYTER_API_HEADERS.X_RELAYTER_API_KEY]: apiKey});

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, JobModel);
        });
    }

}

import {autoserialize, autoserializeAs, serialize, serializeAs} from 'cerialize';
import {UserModel} from './user.model';
import {ITableAction, ITableItem} from '@relayter/rubber-duck';

export enum CONTRACT_OPTIONS {
    YEAR = 'Year',
    MONTH = 'Month'
}

export class ContractModel {
    @autoserialize public period: CONTRACT_OPTIONS;
    @autoserializeAs(Date) public startDate: Date;
}

export class TeamModel implements ITableItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserializeAs(UserModel) public owner: UserModel;
    @autoserialize public disabled: boolean;
    @autoserialize public userLimit: number;
    @autoserialize public userCount: number;
    @autoserialize public permissions: string[] = [];
    @autoserialize public storageLimit: number;
    @autoserialize public publicationItemLimit: number;
    @autoserialize public indesignGenerationLimit: number;
    @autoserialize public svgGenerationLimit: number;
    @autoserialize public afterEffectsGenerationLimit: number;
    @autoserialize public storageUsed: number;
    @autoserialize public publicationItemCreatedInCurrentContractPeriod: number;
    @autoserialize public indesignGenerationsInCurrentContractPeriod: number;
    @autoserialize public svgGenerationsInCurrentContractPeriod: number;
    @autoserialize public afterEffectsGenerationsInCurrentContractPeriod: number;
    @autoserialize public renderCredits: number;
    @autoserialize public renderCreditsUsedInCurrentContractPeriod: number;
    @autoserialize public afterEffectsCreditsPerUnit: number;
    @autoserialize public svgCreditsPerUnit: number;
    @autoserialize public indesignCreditsPerUnit: number;
    @autoserializeAs(ContractModel) public contract: ContractModel;

    public actions: ITableAction[];

    get status() {
      return this.disabled ? 'Disabled' : 'Active';
    }
}

export class TeamPatchModel {
    @serialize public disabled: boolean;
    @serialize public userLimit: number;
    @serialize public storageLimit: number;
    @serialize public publicationItemLimit: number;
    @serialize public indesignGenerationLimit: number;
    @serialize public svgGenerationLimit: number;
    @serialize public afterEffectsGenerationLimit: number;
    @serialize public renderCredits: number;
    @serialize public afterEffectsCreditsPerUnit: number;
    @serialize public svgCreditsPerUnit: number;
    @serialize public indesignCreditsPerUnit: number;
    @serializeAs(ContractModel) public contract: ContractModel;
    constructor(
        disabled?: boolean,
        userLimit?: number,
        storageLimit?: number,
        publicationItemLimit?: number,
        indesignGenerationLimit?: number,
        svgGenerationLimit?: number,
        afterEffectsGenerationLimit?: number,
        renderCredits?: number,
        afterEffectsCreditsPerUnit?: number,
        svgCreditsPerUnit?:number,
        indesignCreditsPerUnit?:number,
        contractModel?: ContractModel)
    {
        this.disabled = disabled;
        this.userLimit = userLimit;
        this.storageLimit = storageLimit;
        this.publicationItemLimit = publicationItemLimit;
        this.indesignGenerationLimit = indesignGenerationLimit;
        this.svgGenerationLimit = svgGenerationLimit;
        this.afterEffectsGenerationLimit = afterEffectsGenerationLimit;

        this.afterEffectsCreditsPerUnit = afterEffectsCreditsPerUnit;
        this.svgCreditsPerUnit = svgCreditsPerUnit;
        this.indesignCreditsPerUnit = indesignCreditsPerUnit;

        this.renderCredits = renderCredits;

        if (contractModel) {
            this.contract = contractModel;
        }
    }
}

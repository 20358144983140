<form [formGroup]="formGroup">
    <nuc-form-field label="User limit">
        <nuc-input formControlName="userLimit" type="number" placeholder="Fill in the user limit"></nuc-input>
    </nuc-form-field>
    <nuc-form-field label="Storage limit">
        <nuc-input formControlName="storageLimit" type="number" placeholder="Fill in the storage limit"></nuc-input>
        <nuc-hint>{{formGroup.value.storageLimit | filesize}}</nuc-hint>
    </nuc-form-field>
    <nuc-form-field label="Publication item creation limit">
        <nuc-input formControlName="publicationItemLimit" type="number" placeholder="Fill in the publication item creation limit"></nuc-input>
    </nuc-form-field>
    <nuc-form-field label="InDesign render units">
        <nuc-input formControlName="indesignGenerationLimit" type="number" placeholder="Fill in the InDesign render units"></nuc-input>
    </nuc-form-field>
    <nuc-form-field label="SVG render units">
        <nuc-input formControlName="svgGenerationLimit" type="number" placeholder="Fill in the SVG render units"></nuc-input>
    </nuc-form-field>
    <nuc-form-field label="After Effects render units">
        <nuc-input formControlName="afterEffectsGenerationLimit" type="number" placeholder="Fill in the After Effects render units"></nuc-input>
    </nuc-form-field>
    <nuc-form-field label="Render credits">
        <nuc-input formControlName="renderCredits" type="number" placeholder="Fill in the Render credits"></nuc-input>
    </nuc-form-field>
    <div class="multipliers">
        <nuc-form-field label="After Effects credits/unit">
            <nuc-input formControlName="afterEffectsCreditsPerUnit" type="number" placeholder="Render credits"></nuc-input>
        </nuc-form-field>
        <nuc-form-field label="SVG  credits/unit">
            <nuc-input formControlName="svgCreditsPerUnit" type="number" placeholder="Render credits"></nuc-input>
        </nuc-form-field>
        <nuc-form-field label="InDesign credits/unit">
            <nuc-input formControlName="indesignCreditsPerUnit" type="number" placeholder="Render credits"></nuc-input>
        </nuc-form-field>
    </div>
    <ng-container formGroupName="contract">
        <nuc-form-field label="Contract period">
            <nuc-dropdown
                formControlName="period"
                placeholder="Choose the contract period"
                [items]="contractPeriodOptions"
                [nullOption]="false"
                [required]="true">
            </nuc-dropdown>
        </nuc-form-field>
        <nuc-form-field label="Contract starts at">
            <nuc-datepicker
                formControlName="startDate"
                placeholder="Choose the start date of the contract">
            </nuc-datepicker>
        </nuc-form-field>
    </ng-container>
</form>

import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {TeamService} from '../../../api/services/team.service';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {finalize, map} from 'rxjs/operators';
import {CONTRACT_OPTIONS, ContractModel, TeamModel, TeamPatchModel} from '../../../models/response/team.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../../classes/toaster.class';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';

export interface IEditTeamUsageData {
    team: TeamModel;
}

class TeamFormatForm {
    userLimit: FormControl<number>;
    storageLimit: FormControl<number>;
    publicationItemLimit: FormControl<number>;
    indesignGenerationLimit: FormControl<number>;
    svgGenerationLimit: FormControl<number>;
    afterEffectsGenerationLimit: FormControl<number>;
    renderCredits: FormControl<number>;
    afterEffectsCreditsPerUnit: FormControl<number>;
    svgCreditsPerUnit: FormControl<number>;
    indesignCreditsPerUnit: FormControl<number>;
    contract: FormGroup<{
        period: FormControl<DropdownItem<string>>
        startDate: FormControl<Date>
    }>
}

@Component({
    selector: 'om-edit-team-usage',
    templateUrl: './edit-team-usage.component.html',
    styleUrls: ['./edit-team-usage.component.scss']
})
export class EditTeamUsageComponent implements OnInit {
    private destroyRef = inject(DestroyRef);

    public formGroup: FormGroup<TeamFormatForm>;
    private saveConfig: ButtonConfig;

    public contractPeriodOptions: DropdownItem<string>[] = Object.entries(CONTRACT_OPTIONS)
        .map(([key, label]) => new DropdownItem(label, key));

    constructor(private teamService: TeamService,
                private fullModalService: FullModalService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IEditTeamUsageData) {
    }

    public ngOnInit(): void {
        this.initModalButtons();
        this.initForm();
    }

    private initForm(): void {
        this.formGroup = new FormGroup<TeamFormatForm>({
            userLimit: new FormControl(this.modalData.team.userLimit, [Validators.required, Validators.min(1)]),
            storageLimit: new FormControl(this.modalData.team.storageLimit, [Validators.required, Validators.min(0)]),
            publicationItemLimit: new FormControl(this.modalData.team.publicationItemLimit, [Validators.required, Validators.min(0)]),
            indesignGenerationLimit: new FormControl(this.modalData.team.indesignGenerationLimit, [Validators.required, Validators.min(0)]),
            svgGenerationLimit: new FormControl(this.modalData.team.svgGenerationLimit, [Validators.required, Validators.min(0)]),
            afterEffectsGenerationLimit: new FormControl(this.modalData.team.afterEffectsGenerationLimit, [Validators.required, Validators.min(0)]),
            renderCredits: new FormControl(this.modalData.team.renderCredits, [Validators.required, Validators.min(0)]),
            afterEffectsCreditsPerUnit: new FormControl(this.modalData.team.afterEffectsCreditsPerUnit, [Validators.required, Validators.min(0.1)]),
            svgCreditsPerUnit: new FormControl(this.modalData.team.svgCreditsPerUnit, [Validators.required, Validators.min(0.1)]),
            indesignCreditsPerUnit: new FormControl(this.modalData.team.indesignCreditsPerUnit, [Validators.required, Validators.min(0.1)]),
            contract: new FormGroup({
                period: new FormControl({
                    value: this.contractPeriodOptions.find((dataFieldOption) =>
                        dataFieldOption.getValue() === this.modalData.team.contract?.period),
                    disabled: !!this.modalData.team.contract?.period
                }, Validators.required),
                startDate: new FormControl(
                    { value: this.modalData.team.contract?.startDate, disabled: !!this.modalData.team.contract?.startDate },
                    Validators.required
                )
            })
        });

        this.formGroup.statusChanges.pipe(
            map((status) => status === 'VALID'),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((valid) => this.saveConfig.disabled = !valid);
    }

    private initModalButtons(): void {
        const cancelConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveConfig = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', false, false, true);

        const cancelAction = new FullModalActionModel(cancelConfig);
        const saveAction = new FullModalActionModel(this.saveConfig);

        cancelAction.observable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.saveTeam());
        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private saveTeam(): void {
        this.saveConfig.loading = true;
        const formGroupValue = this.formGroup.value;

        let contractModel: ContractModel;
        if (formGroupValue.contract) {
            contractModel = {
                period: formGroupValue.contract.period.getValue() as CONTRACT_OPTIONS,
                startDate: formGroupValue.contract.startDate
            };
        }
        const patchBody = new TeamPatchModel(
            undefined,
            formGroupValue.userLimit,
            formGroupValue.storageLimit,
            formGroupValue.publicationItemLimit,
            formGroupValue.indesignGenerationLimit,
            formGroupValue.svgGenerationLimit,
            formGroupValue.afterEffectsGenerationLimit,
            formGroupValue.renderCredits,
            formGroupValue.afterEffectsCreditsPerUnit,
            formGroupValue.svgCreditsPerUnit,
            formGroupValue.indesignCreditsPerUnit,
            contractModel
        )
        this.teamService.patchTeam(this.modalData.team._id, patchBody)
            .pipe(finalize(() => this.saveConfig.loading = false), takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    Toaster.success('Team usage is successfully updated!');
                    this.fullModalService.close(result);
                },
                error: Toaster.handleApiError
            });
    }
}

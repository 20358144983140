import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {TeamModel, TeamPatchModel} from '../../models/response/team.model';
import {TeamService} from '../../api/services/team.service';
import {ARApiError, ARLogger} from '@relayter/core';

@Component({
    selector: 'om-disable-team-form',
    templateUrl: './disable-team-form.component.html',
    styleUrls: ['./disable-team-form.component.scss']
})
export class DisableTeamFormComponent implements OnInit {
    public disableTeamForm: UntypedFormGroup;
    public isLoading = false;

    @Input()
    public team: TeamModel;

    @Output()
    public formSubmittedSuccess: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public formCanceled: EventEmitter<void> = new EventEmitter<void>();

    constructor(private fbs: UntypedFormBuilder, private teamService: TeamService) {

    }

    public ngOnInit(): void {
        const productFormData = {
            teamName: ['']
        };
        this.disableTeamForm = this.fbs.group(productFormData);
    }

    /**
     * Do api call to patch the disabled property on a team
     */
    public onSubmit(): void {
        this.isLoading = true;
        this.teamService.patchTeam(this.team._id, new TeamPatchModel(true))
            .subscribe({
                next: (updatedTeam: TeamModel) => {
                    this.isLoading = false;
                    ARLogger.info(`Team ${updatedTeam.name} successfully disabled`);
                    this.formSubmittedSuccess.emit();
                },
                error: (error: ARApiError) => {
                    this.isLoading = false;
                    alert('disabling team went wrong, Error: ' + error.message);
                }
            });
    }

    /**
     * User pressed cancel in the conform disable team form
     */
    public onCancel(): void {
        this.formCanceled.emit();
    }

}

import {merge, Observable, ReplaySubject} from 'rxjs';
import {delay, filter, map, shareReplay, startWith, withLatestFrom} from 'rxjs/operators';
import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponseBase } from '@angular/common/http';
import {AwsSignedUrlModel} from './aws-signed-url.model';
import {RLFileSizeUtil} from '../../util/file-size.util';
import {RLFileTypeUtil} from '../../util/file-type.util';

export enum EUploadStatus {
    Uploading = 1,
    Done = 101,
    Failed = -1
}

export class UploadModel {
    public readonly filename: string;
    public readonly fileType: string;
    public readonly fileSize: string;
    private s3KeySubject = new ReplaySubject<string>(1);
    private assetPathSubject = new ReplaySubject<string>(1);
    public s3Key$ = this.s3KeySubject.asObservable();
    public progress$: Observable<number>;

    constructor(private file: File, private signedUrl$: Observable<AwsSignedUrlModel>, private upload$: Observable<HttpEvent<Record<string, any>>>) {
        this.filename = file.name;
        this.fileType = RLFileTypeUtil.getExtensionFromFileName(file.name).toLowerCase();
        this.fileSize = RLFileSizeUtil.formatBytesToReadableFileSize(file.size);

        const uploadProgress$ = upload$.pipe(
            filter((httpEvent) => !(httpEvent instanceof Error) && httpEvent.type === HttpEventType.UploadProgress),
            map((httpEvent: HttpProgressEvent) => Math.round(100 * httpEvent.loaded / httpEvent.total)),
            startWith(1),
        );

        const uploadDone$ = upload$.pipe(
            filter((httpEvent) => httpEvent instanceof HttpResponseBase),
            withLatestFrom(signedUrl$),
            map(([httpEvent, signedUrl]) => {
                this.s3KeySubject.next(signedUrl.s3Key);
                this.assetPathSubject.next(signedUrl.signedUrl);
                return httpEvent['ok'] ? EUploadStatus.Done : EUploadStatus.Failed;
            }),
        );

        const uploadFailed$ = upload$.pipe(
            filter((error) => error instanceof Error),
            map(() => EUploadStatus.Failed)
        );

        const uploadDoneOrFailed$ = merge(uploadDone$, uploadFailed$).pipe(
            delay(300)
        );

        this.progress$ = merge(uploadProgress$, uploadDoneOrFailed$).pipe(
            shareReplay(1)
        );
    }
}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BaseRouterModule} from './router/router.module';
import {RouterModule} from '@angular/router';
import {NUCInputsModule, ToastService} from '@relayter/rubber-duck';
import {PagesModule} from './pages/pages.module';
import {OvermindFormsModule} from './forms/overmind-forms.module';
import {JwtIntercepter} from './api/authentication.intercepter';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule} from '@angular/material/core';
import {Toaster} from './classes/toaster.class';
import {OVERMIND_DATE_FORMATS} from './pipes/date.pipe';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        BaseRouterModule,
        RouterModule,
        NUCInputsModule,
        PagesModule,
        OvermindFormsModule,
        MatRippleModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtIntercepter,
            multi: true
        },
        {provide: MAT_DATE_LOCALE, useValue: navigator.language},
        {provide: MAT_DATE_FORMATS, useValue: OVERMIND_DATE_FORMATS}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(toastService: ToastService) {
        Toaster.initialize(toastService);
    }
}
